import { Linear, Power4, gsap } from 'gsap';

// Helper functions

const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
}

const headerHeight = 110, // CSS Header Höhe
    mobileHeaderHeight = 60, // CSS Header Höhe (Mobile)
    breakpointTablet = 768,
    breakpointDesktop = 1025;
let animationsInitialized = false;

initAnimations();
// Event listener
window.addEventListener('scroll',function() {
    let header = document.getElementById('header'),
        colContainer = document.querySelector('#section1 .col-container');
    if (colContainer.getBoundingClientRect().top <= 0) {
        header.classList.add('with-bg');
    } else {
        header.classList.remove('with-bg');
    }
});

function initAnimations() {
    let vh = window.innerHeight,
        vw = window.innerWidth;
    if (!animationsInitialized && vw >= breakpointTablet) {
        animationsInitialized = true;

        let animating = false,
            ignoreAnimation = false, // DEV-Option
            currentSection = 1,
            totalSections = 9,
            durationDefault = 0.7,
            durationBg = 3,
            easeBg = Linear.easeNone,
            elementMoveDefault = 200,
            useTabletWidth = vw < breakpointDesktop;

        // Dimensionen der Elemente wie Rakete, Astronaut etc.
        const planetWidth = 700, planetHeight = 700;
        const raketeWidth = 450, raketeHeight = 450;
        const astronautWidth = useTabletWidth ? 250 : 350, astronautHeight = useTabletWidth ? 452 : 633;
        const moonWidth = 448, moonHeight = 448;
        const cometWidth = 450, cometHeight = 450;
        const satelliteWidth = useTabletWidth ? 250 : 354, satelliteHeight = useTabletWidth ? 300 : 425;

        // Event listener
        window.addEventListener('resize', debounce(function () {
            initAnimations();
            goToSection(currentSection);
        }, 500));
        // Tablet / IPad
        // Determining swipe direction
        // http://stackoverflow.com/a/22257774/1064325
        let touchStartY;
        document.addEventListener('touchstart', function (e) {
            touchStartY = e.touches[0].clientY;
        }, false);

        // Preventing iOS end of page bounce effect
        // http://stackoverflow.com/a/7771215/1064325
        document.addEventListener('touchmove', function (e) {
            e.preventDefault();
        }, false);

        document.addEventListener('touchend', function (e) {
            if (!animating || ignoreAnimation) {
                let nextSection = currentSection;
                let touchEndY = e.changedTouches[0].clientY;
                if (touchStartY > touchEndY + 5) {
                    // slide up
                    if (currentSection < totalSections) {
                        nextSection++;
                    }
                } else if (touchStartY < touchEndY - 5) {
                    // slide down
                    if (currentSection > 1) {
                        nextSection--;
                    }
                }

                if (currentSection !== nextSection) {
                    goToSection(nextSection);
                }
            }
        }, false);
        // Desktop
        window.addEventListener('mousewheel', function (event) {
            if (!animating || ignoreAnimation) {
                let nextSection = currentSection;
                if (event.deltaY < 0) {
                    // upscroll code
                    if (currentSection > 1) {
                        nextSection--;
                    }
                } else {
                    // downscroll code
                    if (currentSection < totalSections) {
                        nextSection++;
                    }
                }

                if (currentSection !== nextSection) {
                    goToSection(nextSection);
                }
            }
        }, false);

        let menulinks = document.getElementsByClassName('go-to-section');
        for (let i = 0; i < menulinks.length; i++) {
            menulinks[i].addEventListener('click', function (event) {
                let nextSection = parseInt(this.getAttribute('data-section'));
                if ((!animating || ignoreAnimation) && nextSection !== currentSection) {
                    goToSection(nextSection);
                }
            });
        }

        // Initialize

        let bgForest = document.getElementById('bgForest');
        let bgForestHeight = window.scrollY + bgForest.getBoundingClientRect().height;
        bgForest.style.bottom = -bgForestHeight + 'px';

        // Initialize timeline
        let tl = gsap.timeline();
        let colContainer = document.querySelector('#section1 .col-container');
        setTimeout(function () {
            if (window.location.hash) {
                goToSection(+window.location.hash.substring(1));
                return;
            }

            tl
                .fromTo('#section1 .headline', {position: 'absolute', bottom: vh}, {
                    duration: durationDefault,
                    position: 'absolute',
                    bottom: colContainer.offsetHeight + headerHeight + 70,
                    opacity: 1
                })
                .fromTo('#section1 .col-container', {position: 'absolute', bottom: -vh}, {
                    duration: durationDefault,
                    position: 'absolute',
                    bottom: headerHeight,
                    opacity: 1
                }, 'all')
                .addLabel('cols')
                .from('#section1 .col2', {duration: durationDefault, y: vh}, 'cols')
                .from('#section1 .col1', {duration: durationDefault, x: -vw}, 'cols')
                .from('#section1 .col3', {duration: durationDefault, x: vw}, 'cols')
            tl.eventCallback('onComplete', function () {
                animating = false;
            });
        }, 1000);

        // Infinite animations
        gsap.to('#section2 .planet', {duration: 120, rotation: '360', ease: Linear.easeNone, repeat: -1});

        function goToSection(section) {
            if (isNaN(section)) {
                return;
            }

            if (history.pushState) {
                history.pushState(null, null, '#' + section);
            } else {
                location.hash = '#' + section;
            }

            animating = true;

            let vh = window.innerHeight,
                vw = window.innerWidth;

            // Hintergrund Animation - TODO: Fehler, wenn animation noch läuft und trotzdem gescrollt wird
            let bg = document.getElementById('bgStars');
            let bgTopPosition = window.scrollY + bg.getBoundingClientRect().top;
            gsap.to('#bgStars', {
                duration: durationBg,
                ease: easeBg,
                y: bgTopPosition + vh / 2 * (currentSection - section)
            });

            // Planet ausblenden
            if (currentSection === 1 && section !== 1) {
                gsap.to('#bgPlanet', {duration: durationBg, ease: easeBg, y: -vh / 2});
                setTimeout(() => {
                    gsap.to('#bgPlanet', {opacity: 0});
                }, durationBg * 1000);
            }
            // Planet einblenden
            if (section === 1) {
                gsap.to('#bgPlanet', {opacity: 1});
                gsap.to('#bgPlanet', {duration: durationBg, ease: easeBg, y: 0});
            }
            let bgForest = document.getElementById('bgForest');
            let bgForestHeight = window.scrollY + bgForest.getBoundingClientRect().height;
            bgForest.style.bottom = -bgForestHeight + 'px';
            // Wald ausblenden
            if (currentSection === totalSections) {
                gsap.to('#bgForest', {duration: durationBg, opacity: 0, ease: easeBg, y: bgForestHeight});
            }
            // Wald einblenden
            if (section === totalSections) {
                gsap.to('#bgForest', {duration: durationBg, opacity: 1, ease: easeBg, y: -bgForestHeight});
            }

            // Moon
            if (section === 6 && currentSection === 5) { // Übergang von 5 zu 6
                gsap.to('#moon', {duration: durationBg, y: -moonHeight / 2});
            } else if (currentSection === 6 && section === 5) {  // Übergang von 6 zu 5
                gsap.to('#moon', {duration: durationBg, y: vh - moonHeight / 3});
            } else if (currentSection === 6 && section !== 5) {  // Übergang von 6 zu X
                gsap.to('#moon', {duration: durationBg, opacity: 0, y: -moonHeight});
            } else if (section === 5 && currentSection < 5) { // Einblenden von <5 zu 5
                gsap.fromTo('#moon', {opacity: 0, y: vh}, {duration: durationBg, opacity: 1, y: vh - moonHeight / 3});
            } else if (section === 5 && currentSection > 5) { // Einblenden von >5 zu 5
                gsap.fromTo('#moon', {opacity: 0, y: -moonHeight}, {
                    duration: durationBg,
                    opacity: 1,
                    y: vh - moonHeight / 3
                });
            } else if (section === 6 && currentSection < 6) { // Einblenden von <6 zu 6
                gsap.fromTo('#moon', {opacity: 0, y: vh}, {duration: durationBg, opacity: 1, y: -moonHeight / 2});
            } else if (section === 6 && currentSection > 6) { // Einblenden von >6 zu 6
                gsap.fromTo('#moon', {opacity: 0, y: -moonHeight}, {
                    duration: durationBg,
                    opacity: 1,
                    y: -moonHeight / 2
                });
            } else { // Ausblenden (einfach)
                gsap.to('#moon', {duration: durationDefault, opacity: 0});
            }

            document.getElementById('section' + currentSection).classList.remove('active');
            document.getElementById('section' + section).classList.add('active');

            tl.totalProgress(1);
            tl.clear();

            // Hiding animations
            switch (currentSection) {
                case 1:
                    let colContainer = document.querySelector('#section1 .col-container');
                    tl
                        .to('#section1 .headline', {
                            duration: durationDefault / 2,
                            opacity: 0,
                            bottom: colContainer.offsetHeight + headerHeight + 170
                        })
                        .to('#section1 .col-container', {
                            duration: durationDefault,
                            opacity: 0,
                            bottom: headerHeight + 200
                        })
                    break;
                case 2:
                    tl
                        .addLabel('all')
                        .to('#section2 .container-fluid', {
                            duration: durationDefault,
                            opacity: 0,
                            y: -elementMoveDefault
                        }, 'all')
                        .to('#section2 .planet', {
                            duration: durationDefault,
                            ease: Power4.easeInOut,
                            opacity: 0,
                            y: 0
                        }, 'all')
                    break;
                case 3:
                    tl
                        .addLabel('all')
                        .to('#section3 .container1, #section3 .container2', {
                            duration: 1.5,
                            bottom: -vh,
                            opacity: 0
                        }, 'all')
                        .to('#section3 .rakete', {duration: 1.5, opacity: 0, y: -vh / 2}, 'all')
                    break;
                case 4:
                    tl
                        .addLabel('all')
                        .to('#section4 .container-fluid', {duration: durationDefault, opacity: 0}, 'all')
                        .to('#section4 .astronaut', {duration: durationDefault, opacity: 0, x: 0, y: 0}, 'all')
                    break;
                case 5:
                    tl
                        .addLabel('all')
                        .to('#section5 .container-fluid', {duration: durationDefault, opacity: 0}, 'all')
                    break;
                case 6:
                    tl
                        .addLabel('all')
                        .to('#section6 .container-fluid', {duration: durationDefault, opacity: 0}, 'all')
                    break;
                case 7:
                    tl
                        .addLabel('all')
                        .to('#section7 .container-fluid', {duration: durationDefault, opacity: 0}, 'all')
                        .to('#section7 .comet', {duration: durationDefault, opacity: 0, x: vw, y: vh}, 'all')
                    break;
                case 8:
                    tl
                        .addLabel('all')
                        .to('#section8 .container-fluid', {duration: durationDefault, opacity: 0}, 'all')
                        .to('#section8 .satellite', {
                            duration: durationDefault,
                            opacity: 0,
                            x: 0,
                            y: -satelliteHeight
                        }, 'all')
                    break;
                case 9:
                    tl
                        .addLabel('all')
                        .to('#section9 .container-fluid', {duration: durationDefault, opacity: 0}, 'all')
                        .to('wc-footer', {duration: durationDefault, opacity: 0, y: vh}, 'all')
                    break;
            }
            // Showing animations
            switch (section) {
                case 1:
                    let colContainer = document.querySelector('#section1 .col-container');
                    tl
                        .fromTo('#section1 .col-container', {
                            position: 'absolute',
                            bottom: headerHeight + 200
                        }, {duration: durationDefault, position: 'absolute', bottom: headerHeight, opacity: 1})
                        .fromTo('#section1 .headline', {
                            position: 'absolute',
                            bottom: colContainer.offsetHeight + headerHeight + 170
                        }, {
                            duration: durationDefault,
                            position: 'absolute',
                            bottom: colContainer.offsetHeight + headerHeight + 70,
                            opacity: 1
                        })
                    break;
                case 2:
                    tl
                        .addLabel('all')
                        .fromTo('#section2 .planet', {opacity: 0, x: vw / 2, y: vh / 2}, {
                            duration: 1,
                            opacity: 1,
                            y: vh / 2 - planetHeight / 2
                        }, 'all')
                        .fromTo('#section2 .container-fluid', {opacity: 0, y: elementMoveDefault}, {
                            duration: 2,
                            opacity: 1,
                            y: 0
                        }, 'all')
                    break;
                case 3:
                    let container1 = document.querySelector('#section3 .container1');
                    let container2 = document.querySelector('#section3 .container2');
                    let containerWidth = document.querySelector('#section3 .container2 .container').getBoundingClientRect().width;
                    let yRakete = vh - container1.offsetHeight / 2 - container2.offsetHeight - headerHeight;

                    // Ipad and Macbook optimization
                    if (vh >= 768 && vh <= 900) {
                        yRakete = vh - container1.offsetHeight / 2 - container2.offsetHeight - headerHeight - 40;
                    }

                    tl
                        .addLabel('all')
                        .fromTo('#section3 .container1', {position: 'absolute', bottom: -vh}, {
                            duration: durationDefault,
                            position: 'absolute',
                            bottom: container2.offsetHeight + headerHeight,
                            opacity: 1
                        }, 'all')
                        .fromTo('#section3 .container2', {position: 'absolute', bottom: -vh}, {
                            duration: durationDefault,
                            position: 'absolute',
                            bottom: headerHeight,
                            opacity: 1
                        }, 'all')
                        .fromTo('#section3 .rakete', {
                            opacity: 0,
                            x: vw / 2 - containerWidth / 2 - raketeWidth / 2,
                            y: vh
                        }, {
                            duration: 1.5,
                            opacity: 1,
                            y: yRakete
                        }, 'all')
                    break;
                case 4:
                    tl
                        .addLabel('all')
                        .to('#section4 .container-fluid', {duration: durationDefault, opacity: 1}, 'all')
                        .fromTo('#section4 .astronaut', {opacity: 0, x: vw, y: vh}, {
                            duration: durationDefault,
                            opacity: 1,
                            x: vw / 2 + astronautWidth / 2,
                            y: vh / 2 - astronautHeight / 2 + headerHeight / 2,
                            rotate: -10
                        }, 'all')
                    break;
                case 5:
                    tl
                        .addLabel('all')
                        .to('#section5 .container-fluid', {duration: durationDefault, opacity: 1}, 'all')
                    break;
                case 6:
                    tl
                        .addLabel('all')
                        .to('#section6 .container-fluid', {duration: durationDefault, opacity: 1}, 'all')
                    break;
                case 7:
                    let containerBCR = document.querySelector('#section6 .container-fluid').getBoundingClientRect();
                    tl
                        .addLabel('all')
                        .to('#section7 .container-fluid', {duration: durationDefault, opacity: 1}, 'all')
                        .fromTo('#section7 .comet', {opacity: 0, x: 0, y: 0}, {
                            duration: durationDefault,
                            opacity: 1,
                            x: vw / 2,
                            y: containerBCR.top + containerBCR.height - cometHeight / 3
                        }, 'all')
                    break;
                case 8:
                    let col = document.querySelector('#section7 .container .col').getBoundingClientRect();
                    tl
                        .addLabel('all')
                        .to('#section8 .container-fluid', {duration: durationDefault, opacity: 1}, 'all')
                        .fromTo('#section8 .satellite', {
                            opacity: 0,
                            x: vw / 2,
                            y: vh + satelliteHeight
                        }, {
                            duration: durationDefault,
                            opacity: 1,
                            x: vw / 2 - satelliteWidth - (col.width - satelliteWidth) / 2,
                            y: vh / 2 - satelliteHeight / 2
                        }, 'all')
                    break;
                case 9:
                    tl
                        .addLabel('all')
                        .to('#section9 .container-fluid', {duration: durationDefault, opacity: 1}, 'all')
                        .fromTo('wc-footer', {y: vh}, {duration: durationBg, opacity: 1, y: 0}, 'all')
                    break;
            }
            tl.eventCallback('onComplete', function () {
                animating = false;
            });

            currentSection = section;
        }
    } else {
        function scrollToSection(section) {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: document.querySelector('#section' + section).offsetTop - mobileHeaderHeight
            });
        }

        if (window.location.hash) {
            setTimeout(scrollToSection.bind(this, +window.location.hash.substring(1)), 500);
        }

        let menulinks = document.getElementsByClassName('go-to-section');
        for (let i = 0; i < menulinks.length; i++) {
            menulinks[i].addEventListener('click', function (event) {
                let section = parseInt(this.getAttribute('data-section'));
                scrollToSection(section);
            });
        }
    }
}